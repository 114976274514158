<spinner
  [backgroundColor]="'#006bb7'"
  [spinner]="spinkit.skThreeBounce"
  [debounceDelay]="200"
>
</spinner>
<!--<p-toast position="top-left"></p-toast>-->

<p-toast (life)="900000" (sticky)="false" [baseZIndex]="10020" styleClass="custom-success" key="success" [showTransformOptions]="'translateX(-100%)'"></p-toast>
<p-toast (life)="400000" (sticky)="false" [baseZIndex]="10020" styleClass="custom-info" key="info" [showTransformOptions]="'translateX(-100%)'"></p-toast>
<p-toast (life)="400000" (sticky)="false" [baseZIndex]="10020" styleClass="custom-warn" key="warn" [showTransformOptions]="'translateX(-100%)'"></p-toast>
<p-toast (life)="400000" (sticky)="false" [baseZIndex]="10020" styleClass="custom-error" key="error" [showTransformOptions]="'translateX(-100%)'"></p-toast>

<router-outlet></router-outlet>


