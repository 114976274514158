import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ConfirmationService,
    PrimeNGConfig,
    MessageService,
    ConfirmEventType,
} from "primeng/api";
import {
    EditarCategoriaService,
    ICategories,
    IFiles,
} from "./editar-categoria.service";
import { AuthService } from "@service/core/auth.service";

@Component({
    selector: "app-editar-categoria",
    templateUrl: "./editar-categoria.component.html",
    styleUrls: ["./editar-categoria.component.css"],
    providers: [ConfirmationService, MessageService],
})
export class EditarCategoriaComponent implements OnInit {
    items = [
        {
            label: "Lista de Categorias",
            routerLink: "/documentos-ajuda/categorias",
        },
        { label: "Editar Categoria" },
    ];
    home = { icon: "pi pi-home", routerLink: "/documentos-ajuda" };

    formEditCategory: FormGroup;
    category: ICategories;

    visibilidade: boolean = true;
    loading: boolean = true;

    cortes: IFiles[] = [];

    totalRecords: number;
    categoryId: number;
    iDFiles: number[] = [];
    rows: number = 10;

    constructor(
        private editarCategoria: EditarCategoriaService,
        private primengConfig: PrimeNGConfig,
        private router: Router,
        public authService: AuthService,
        private route: ActivatedRoute,
        private FormBuilder: FormBuilder,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit(): void {
        this.formEditCategory = this.FormBuilder.group({
            nome: ["", [Validators.required]],
            visibilidade: [],
        });
        this.categoryId = this.route.snapshot.params.id;
        this.editarCategoria.getCategoryById(this.categoryId).subscribe(
            (category) => {
                this.category = category;
                this.cortes = [...this.category.arquivos].splice(0, this.rows);
                this.totalRecords = category.arquivos.length;
                this.formEditCategory.get("nome").setValue(category.nome);
                this.formEditCategory
                    .get("visibilidade")
                    .setValue(category.visibilidade);
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar categoria",
                });
            }
        );

        if (this.category) {
            this.loading = false;
            this.primengConfig.ripple = true;
        }
    }

    goBackToCategorys() {
        this.router.navigate(["documentos-ajuda/categorias"]);
    }

    goToEditFile(idFile: number) {
        this.router.navigate([
            `documentos-ajuda/categoria/${this.categoryId}/editar-arquivo/${idFile}`,
        ]);
    }

    deleteFile(codigoArquivo: number) {
        this.iDFiles.push(codigoArquivo);
        this.category = {
            ...this.category,
            arquivos: this.category.arquivos.filter(
                (item) => item.codigoArquivo !== codigoArquivo
            ),
        };
    }

    openModalDelete(codigoArquivo: number) {
        this.confirmationService.confirm({
            message: "Tem certeza que deseja excluir esse arquivo?",
            icon: "pi pi-info-circle",
            acceptLabel: "Sim",
            rejectLabel: "Não",
            accept: () => {
                this.deleteFile(codigoArquivo);
            },
        });
    }
    // changePage(e: { first: number }) {
    //     this.cortes = this.category.arquivos.slice(
    //         e.first,
    //         e.first + this.rows
    //     );
    // }

    sendData() {
        const categoryData = this.formEditCategory.getRawValue();
        this.editarCategoria
            .updateCategory(categoryData, this.categoryId)
            .subscribe(
                (response) => {
                    this.iDFiles.map((arquivos) => {
                        this.editarCategoria
                            .deleteFiles(arquivos)
                            .subscribe(() => {});
                    });
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Categoria editada com sucesso",
                    });
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: error.error.message,
                    });
                }
            );
        setTimeout(() => {
            this.router.navigate(["documentos-ajuda/categorias"]);
        }, 1500);
    }
}
