<app-breadcrumb [items]="items" [home]="home"></app-breadcrumb>
<div class="p-5 lg:p-8 w-full flex justify-content-center">
    <div
        class="w-6 p-5"
        style="
            background: #fff;
            border-radius: 15px 15px 15px 15px;
            min-width: 400px;
        "
    >
        <form [formGroup]="formNewCategory" (ngSubmit)="sendCategory()">
            <h2>Cadastrar Categoria</h2>
            <div class="flex justify-content-between">
                <div class="flex flex-column w-12">
                    <div
                        class="flex justify-content-between align-items-center"
                    >
                        <div class="flex flex-column w-12 mr-4">
                            <h6>Nome</h6>
                            <input
                                type="text"
                                pInputText
                                id="inputName"
                                placeholder="Nome"
                                formControlName="nome"
                                [ngClass]="[
                                    formNewCategory.get('nome')?.errors
                                        ?.required &&
                                    formNewCategory.get('nome').touched
                                        ? 'ng-dirty'
                                        : ''
                                ]"
                                class="w-full mr-4"
                            />
                        </div>
                        <div
                            class="flex h-full flex-column align-items-center justify-content-between"
                        >
                            <label class="mr-2">Status</label>
                            <p-inputSwitch
                                (onChange)="handleInputValue()"
                                formControlName="inputChecked"
                            ></p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 flex justify-content-end">
                <button
                    label="Cancelar"
                    pButton
                    type="button"
                    class="p-button-danger mr-3"
                    (click)="goBack()"
                ></button>
                <p-button type="submit" label="Salvar"></p-button>
            </div>
        </form>
    </div>
</div>
<p-toast></p-toast>
