import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@service/core/auth.service";
import { environment } from "../../../../environments/environment";

export interface IFiles {
    codigoArquivo: number;
    nome: string;
    categoria: ICategories;
    visibilidade: boolean;
    qtdDownloads: number;
    dataCriacao: string;
}

export interface ICategories {
    nome: string;
    visibilidade: boolean;
    codigoCategoria: number;
}

export interface IResponse {
    content: IFiles[];
    totalElements: number;
}

@Injectable({
    providedIn: "root",
})
export class ResultadoPesquisaService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    getArquivos(
        page: number,
        search?: string,
        direction?: string,
        sort?: string,
        dateInicial?: string,
        dateFinal?: string
    ) {
        if (!search) {
            search = null;
        }
        if (!dateFinal && !dateInicial) {
            dateFinal = "";
            dateInicial = "";
        }

        return this.http.get<IResponse>(
            `${environment.baseUrl}/arquivos?${
                search ? `search=${search}&` : ""
            }page=${page}&size=10&direction=${direction}&sort=${sort}${
                dateInicial &&
                dateFinal &&
                `&dateInicial=${dateInicial}&dateFinal=${dateFinal}`
            }`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }

    getDownloadFile(id: number) {
        return this.http.get(`${environment.baseUrl}/arquivos/download/${id}`, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
            observe: "response",
            responseType: "blob",
        });
    }
}
