import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map, switchMap, tap } from "rxjs/operators";
import { ServicosService } from "@service/core/servicos.service";
import { of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    public usuario: any;
    public menus: any;
    public access_token: any;
    public refresh_token: any;
    public alteracaoSenha = { senhaAtual: null, login: null };
    private readonly headers = {
        headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
        }),
    };
    public informacoes: any;
    autenticado: boolean;
    urlAutenticacaoSistema: any;

    constructor(
        public router: Router,
        public http: HttpClient,
        public servicos: ServicosService
    ) {}

    private createSessao() {
        this.usuario = JSON.parse(localStorage.getItem("usuario"));
        this.menus = JSON.parse(localStorage.getItem("menus"));
        this.access_token = localStorage.getItem("access_token");
    }

    login(login: string, senha: string, lembrar: boolean = false) {
        const urlAutenticacaoSistema = this.servicos.urls.urlAutenticacaoSistema
            .replace("{login}", login)
            .replace("{senha}", encodeURIComponent(senha));
        return this.http.post(urlAutenticacaoSistema, null, this.headers).pipe(
            tap((res) => {
                this.configurarSessao(res, lembrar);
            })
        );
    }

    refreshToken() {
        const refreshToken = this.refresh_token || localStorage.getItem('refresh_token');
        let dados = this.informacoes || localStorage.getItem('info_token');
        dados = atob(dados);

        if(!dados){
            return
        }
        let cliente_id = atob(dados.split(":")[0]);
        let cliente_secret = atob(dados.split(":")[2]);
        const formatted_client_id = cliente_id.slice(0, -3);
        const formatted_client_secret = cliente_secret.slice(0, -3);
        
        const urlRefreshTokenSistema =
            this.urlAutenticacaoSistema +
            "oauth2/token"

        const dataToSend = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('client_id', formatted_client_id)
        .set('client_secret', formatted_client_secret)
        .set('refresh_token', refreshToken)

        return this.http
            .post<any>(urlRefreshTokenSistema, dataToSend, this.headers)
            .pipe(
                tap((res) => {
                    this.access_token = res.access_token;
                    this.refresh_token = res.refresh_token;
                    this.saveToken(res);
                })
            );
    }

    alterarSenha(request: any, login: string) {
        const urlAlteracaoSenha = this.servicos.urls.urlAlteracaoSenha.replace(
            "{login}",
            login
        );
        return this.http.put(urlAlteracaoSenha, request);
    }

    alterarImagem(foto: any) {
        const urlAlteracaoFoto =
            this.servicos.urls.urlPesquisaUsuarios + this.usuario.id + "/foto";
        return this.http.put(urlAlteracaoFoto, foto);
    }

    recuperarSenha(login: string) {
        const urlRecuperacaoSenha =
            this.servicos.urls.urlRecuperacaoSenha.replace("{login}", login);
        return this.http.post(urlRecuperacaoSenha, null, this.headers);
    }

    configurarSessao(sessao: any, lembrar: boolean = false) {
        console.log("configurar");
        this.usuario = sessao.usuario;
        this.menus = sessao.menus;
        this.access_token = sessao.access_token;
        this.refresh_token = sessao.access_token;
        if (lembrar) {
            this.saveSession(sessao);
        }
    }

    saveSession(sessao: any) {
        this.saveToken(sessao);
        localStorage.setItem("usuario", JSON.stringify(sessao.usuario));
        localStorage.setItem("menus", JSON.stringify(sessao.menus));
    }

    private saveToken(sessao: any) {
        localStorage.setItem("access_token", sessao.access_token);
        localStorage.setItem("token_type", sessao.token_type);
        localStorage.setItem("expires_in", sessao.expires_in);
        localStorage.setItem("refresh_token", sessao.refresh_token);
        this.refresh_token = sessao.refresh_token;
    }

    logout(v2?) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("expires_in");
        localStorage.removeItem('info_token')
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("usuario");
        localStorage.removeItem("menus");
        
        if (!v2) {
            this.router.navigate(["/login"]);
        }
    }

    public buscarInformacoes(routerActive: ActivatedRoute, gerarToken: boolean) {
        return routerActive.queryParams.pipe(
            switchMap((params: any) => {
                if(params['informacoes']){
                    this.informacoes = params['informacoes'];
                    localStorage.setItem("info_token", this.informacoes);
                }
                gerarToken = gerarToken || !this._infoTokenEhValido(this.informacoes);
                return this.decode(this.informacoes, gerarToken);
            })
        )
    }

    public decode(informacoes: string, gerarToken) {
        let dados = atob(informacoes);
        let cliente_id = atob(dados.split(":")[0]);
        let login = atob(dados.split(":")[1]);
        let cliente_secret = atob(dados.split(":")[2]);
        let authorizations = atob(dados.split(":")[3]);
        let urlAutenticacaoSistema = atob(dados.split(":")[4]); 
        this.urlAutenticacaoSistema = urlAutenticacaoSistema;
        // console.log(
        //     `cliente_id: ${cliente_id} login:${login} cliente_secret: ${cliente_secret}, authorizations: ${authorizations}, url: ${urlAutenticacaoSistema}`
        // );
        if (gerarToken) {
            return this.buscarTokenAutenticado(
                cliente_id,
                login,
                cliente_secret,
                authorizations,
                urlAutenticacaoSistema
            );
        }
        return of(void 0);
    }

    public buscarTokenAutenticado(
        cliente_id: string,
        login: string,
        cliente_secret: string,
        authorizations: string,
        urlAutenticacaoSistema: string
    ) {
        const informacao = this.encode(cliente_id, login, cliente_secret);
        const urlAutenticacao = urlAutenticacaoSistema + "v2-oauth2/tokenAutenticado";
        let headersComToken = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${authorizations}`,
            }),
        };

        return this.http
            .post(urlAutenticacao, informacao, headersComToken)
            .pipe(map((res) => this.configurarSessao(res, true)) )
    }

    private encode(cliente_id: string, login: string, cliente_secret: string) {
        const dados =
            btoa(cliente_id) + ":" + btoa(login) + ":" + btoa(cliente_secret);
        return { informacoes: btoa(dados) };
    }

    public iniciaAutenticacao(routerActive: ActivatedRoute) {
        return this.verificaSeExiteToken(routerActive).pipe(map(() => {
                this.access_token = localStorage.getItem("access_token");
                return this.access_token != null;
            }))
    }

    public verificaSeExiteToken(routerActive: ActivatedRoute) {
        this.access_token = localStorage.getItem("access_token");
        return this.buscarInformacoes(routerActive, this.access_token == null);
    }

    private _infoTokenEhValido(infoToken) {
        const token = localStorage.getItem("info_token");
        if (token == null) {
            this._salvaInfoToken(infoToken);
            return false;
        }
        if (token === infoToken)
            return true;
        this.logout(true);
        this._salvaInfoToken(infoToken);
        return false;
    }

    private _salvaInfoToken = (infoToken) =>
        localStorage.setItem("info_token", infoToken);
}
