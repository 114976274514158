<div
    class="p-5 lg:p-8 w-full flex flex-column justify-content-center align-items-center"
>
    <div class="w-10">
        <div class="w-full flex justify-content-between align-items-center">
            <div
                class="flex justify-content-start w-12 md:w-10 align-items-center"
            >
                <span class="p-input-icon-left w-7 h-full md: w-8">
                    <i class="pi pi-search"></i>
                    <input
                        type="text"
                        pInputText
                        placeholder="Pesquisar"
                        style="min-width: 150px"
                        class="w-full"
                        (change)="storeSearch($event)"
                    />
                </span>
                <div>
                    <button
                        pButton
                        type="button"
                        label="Buscar"
                        style="min-width: 75px"
                        class="ml-2"
                        (click)="goSearch()"
                    ></button>
                </div>
            </div>
            <div *ngIf="masterDN">
                <button
                    style="
                        border: none;
                        background: transparent;
                        cursor: pointer;
                    "
                    (click)="goCategories()"
                >
                    <div class="flex align-items-center">
                        <i class="pi pi-inbox mr-3" style="font-size: 22px"></i>
                        <span>Categorias</span>
                    </div>
                </button>
            </div>
        </div>
        <div
            class="mt-5 w-full p-5"
            style="background: white; border-radius: 15px 15px 0px 0px"
        >
            <div *ngIf="currentPage === 0; else gridSix">
                <div
                    class="flex justify-content-between flex-column md:flex-row"
                >
                    <div class="w-full md:w-5">
                        <span>Postagem em destaque</span>
                        <div class="mt-5" *ngIf="emphasisArchives.length">
                            <p-accordion
                                *ngFor="let emphasisArchive of emphasisArchives"
                            >
                                <p-accordionTab
                                    header="{{ emphasisArchive.nome }}"
                                    ><div
                                        class="w-full flex justify-content-end align-items-center"
                                    >
                                        <span>{{
                                            emphasisArchive.qtdDownloads ===
                                            null
                                                ? 0 + " downloads"
                                                : emphasisArchive.qtdDownloads +
                                                  " downloads"
                                        }}</span>

                                        <p-button
                                            class="ml-4"
                                            label="Download do arquivo"
                                            icon="pi pi-download"
                                            iconPos="left"
                                            (click)="
                                                downloadFile(
                                                    emphasisArchive.codigoArquivo,
                                                    emphasisArchive.nome
                                                )
                                            "
                                        ></p-button>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                    <div class="w-full md:w-5 mt-5 md:mt-0">
                        <span>Últimos cadastrados</span>
                        <div class="mt-5 w-full" *ngIf="lastArchives.length">
                            <p-accordion
                                *ngFor="let lastArchive of lastArchives"
                            >
                                <p-accordionTab header="{{ lastArchive.nome }}"
                                    ><div
                                        class="w-full flex justify-content-end align-items-center"
                                    >
                                        <span>{{
                                            lastArchive.qtdDownloads === null
                                                ? 0 + " downloads"
                                                : lastArchive.qtdDownloads +
                                                  " downloads"
                                        }}</span>

                                        <p-button
                                            class="ml-4"
                                            label="Download do arquivo"
                                            icon="pi pi-download"
                                            iconPos="left"
                                            (click)="
                                                downloadFile(
                                                    lastArchive.codigoArquivo,
                                                    lastArchive.nome
                                                )
                                            "
                                        ></p-button>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                </div>
                <div class="gridCategories" *ngIf="categories.length">
                    <div *ngFor="let categoria of categories">
                        <div class="w-full mt-5">
                            <span>{{ categoria.nome }}</span>
                            <div
                                class="mt-5 w-full"
                                *ngIf="
                                    categoria.arquivos.length;
                                    else noContent
                                "
                            >
                                <p-accordion
                                    *ngFor="
                                        let arquivo of toArray(
                                            categoria.arquivos
                                        )
                                    "
                                >
                                    <p-accordionTab header="{{ arquivo.nome }}"
                                        ><div
                                            class="w-full flex justify-content-end align-items-center"
                                        >
                                            <span>{{
                                                arquivo.qtdDownloads === null
                                                    ? 0 + " downloads"
                                                    : arquivo.qtdDownloads +
                                                      " downloads"
                                            }}</span>
                                            <p-button
                                                class="ml-4"
                                                label="Download do arquivo"
                                                icon="pi pi-download"
                                                iconPos="left"
                                                (click)="
                                                    downloadFile(
                                                        arquivo.codigoArquivo
                                                    )
                                                "
                                            ></p-button>
                                        </div>
                                    </p-accordionTab>
                                </p-accordion>
                                <div class="flex justify-content-end w-100">
                                    <span
                                        *ngIf="
                                            categoria.todosArquivos.length > 5
                                        "
                                        (click)="
                                            getAllFiles(
                                                categoria.codigoCategoria
                                            )
                                        "
                                        style="
                                            cursor: pointer;
                                            font-size: 15px;
                                            color: #007bff;
                                        "
                                    >
                                        {{
                                            categoria.arquivos.length > 5
                                                ? "Ver menos"
                                                : "Ver mais"
                                        }}
                                    </span>
                                </div>
                            </div>
                            <ng-template #noContent>
                                <div><span>Sem arquivos...</span></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #gridSix>
                <div class="gridCategoriesSix" *ngIf="categories.length">
                    <div *ngFor="let categoria of categories">
                        <div class="w-full mt-5">
                            <span>{{ categoria.nome }}</span>
                            <div
                                class="mt-5 w-full"
                                *ngIf="
                                    categoria.arquivos.length;
                                    else noContent
                                "
                            >
                                <p-accordion
                                    *ngFor="
                                        let arquivo of toArray(
                                            categoria.arquivos
                                        )
                                    "
                                >
                                    <p-accordionTab header="{{ arquivo.nome }}"
                                        ><div
                                            class="w-full flex justify-content-end align-items-center"
                                        >
                                            <span>{{
                                                arquivo.qtdDownloads === null
                                                    ? 0 + " downloads"
                                                    : arquivo.qtdDownloads +
                                                      " downloads"
                                            }}</span>
                                            <p-button
                                                class="ml-4"
                                                label="Download do arquivo"
                                                icon="pi pi-download"
                                                iconPos="left"
                                                (click)="
                                                    downloadFile(
                                                        arquivo.codigoArquivo,
                                                        arquivo.nome
                                                    )
                                                "
                                            ></p-button>
                                        </div>
                                    </p-accordionTab>
                                </p-accordion>
                                <div class="flex justify-content-end w-100">
                                    <span
                                        *ngIf="
                                            categoria.todosArquivos.length > 5
                                        "
                                        (click)="
                                            getAllFiles(
                                                categoria.codigoCategoria
                                            )
                                        "
                                        style="
                                            cursor: pointer;
                                            font-size: 15px;
                                            color: #007bff;
                                        "
                                    >
                                        {{
                                            categoria.arquivos.length > 5
                                                ? "Ver menos"
                                                : "Ver mais"
                                        }}
                                    </span>
                                </div>
                            </div>
                            <ng-template #noContent>
                                <div><span>Sem arquivos...</span></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div
            class="w-full flex justify-content-center"
            style="background: white; border-radius: 0px 0px 15px 15px"
        >
            <div class="w-8">
                <p-paginator
                    [rows]="6"
                    [totalRecords]="totalRecords"
                    (onPageChange)="changePage($event)"
                ></p-paginator>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>
