import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@service/core/auth.service";
import { PermissaoPerfil } from "@shared/enum/permissao-perfil.enum";
import { MegaMenuItem, TreeNode } from "primeng/api";
import { switchMap } from "rxjs/operators";
import { DocumentosAjudaService } from "./documentos-ajuda.service";

@Component({
    selector: "app-documentos-ajuda",
    templateUrl: "./documentos-ajuda.component.html",
    styleUrls: ["./documentos-ajuda.component.css"],
})
export class DocumentosAjudaComponent implements OnInit {
    informacoes: any;
    access_token: string;
    nomeTela: string;
    autenticado: any;
    files2: TreeNode[];
    testeJson: any;
    itemsMenu: MegaMenuItem[];
    authenticated = false;
    isLoading: boolean;
    masterDN: boolean;

    constructor(
        public router: Router,
        public routerActive: ActivatedRoute,
        public authService: AuthService,
        public service: DocumentosAjudaService
    ) {}

    ngOnInit() {
        this.isLoading = true;        
        this.authService.logout(true)        
        this.service.getFiles().then((files) => (this.files2 = files));
        this.authService
        .iniciaAutenticacao(this.routerActive)
        .subscribe((resolver) => {
            this.nomeDaTela(resolver);
            this.isLoading = false;
        });

        this.itemsMenu = [
            {
                label: "Categorias",
                icon: "pi pi-fw pi-inbox",
                routerLink: ["/documentos-ajuda/categorias"],
            },
        ];
    }

    nomeDaTela(res) {
        const user = JSON.parse(localStorage?.getItem("usuario"))
        this.masterDN = user?.tipoPerfil === "DN" && user?.perfil === PermissaoPerfil['MASTER_DN'];

        if (res == true || res == "true") {
            this.nomeTela = "Usuario Autenticado!";
            this.authenticated = true;
        } else {
            this.nomeTela = "Usuario não Autenticado!";
            this.authenticated = false;
        }
    }

    sair() {
        this.nomeDaTela(false);
        this.authService.logout("v2");
    }

    expandAll() {
        this.files2.forEach((node) => {
            this.expandRecursive(node, true);
        });
    }

    collapseAll() {
        this.files2.forEach((node) => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    goCategories() {
        this.router.navigate([`documentos-ajuda/categorias`]);
    }
}
