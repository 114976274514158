import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthService } from "@service/core/auth.service";

export interface ICategories {
    nome: string;
    visibilidade: string;
    codigoCategoria: number;
}
export interface IResponse {
    content: ICategories[];
    totalElements: number;
    arquivos: [];
}

@Injectable({
    providedIn: "root",
})
export class CategoriasService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    getCategories(page: number, search?: string) {
        if (!!!search) {
            search = "";
        }

        return this.http.get<IResponse>(
            `${environment.baseUrl}/categorias?search=${search}&page=${page}&size=10&direction=ASC&sort=nome`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }

    getOneCategory(id: number) {
        return this.http.get<IResponse>(
            `${environment.baseUrl}/categorias/${id}`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }

    deleteCategory(id: number) {
        return this.http.delete(`${environment.baseUrl}/categorias/${id}`, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }
}
