import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
} from "@angular/router";
import { AlertaService } from "@service/core/alerta.service";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { AlertaComponent } from "@shared/alerta/alerta.component";
import { SpinnerVisibilityService, Spinkit } from "ng-http-loader";
import { AuthService } from "@service/core/auth.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
    providers: [AlertaComponent, MessageService],
})
export class AppComponent implements OnInit  {
    public spinkit = Spinkit;

    constructor(
        private router: Router,
        private spinner: SpinnerVisibilityService,
        public alerta: AlertaService,
        private config: PrimeNGConfig,
    ) {}

    ngOnInit() {
        this.config.setTranslation({
            dayNames: [
                "Domingo",
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sábado",
            ],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
            monthNames: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            monthNamesShort: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
            ],
        });
        this.router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.spinner.show();
            } else if (event instanceof RouteConfigLoadEnd) {
                this.spinner.hide();
            }
        });
    }
}
