<p-toast></p-toast>
<app-breadcrumb [items]="items" [home]="home"></app-breadcrumb>
<div class="flex flex-column align-items-center p-8 w-full">
    <div class="w-10" style="min-width: 400px">
        <div
            style="
                background: #fff;
                border-radius: 15px 15px 15px 15px;
                min-width: 400px;
            "
            class="w-6 p-5"
        >
            <h2>Editar arquivo</h2>
            <form
                [formGroup]="formRegister"
                (ngSubmit)="updateFile()"
                (keydown.enter)="$event.preventDefault()"
            >
                <div class="w-full">
                    <div
                        class="form-group flex flex-column align-items-start flex-column-reverse xl:flex-row justify-content-between"
                    >
                        <div class="flex flex-column w-full">
                            <div
                                class="xl:flex align-items-center justify-content-between w-full"
                            >
                                <div class="w-full xl:w-7">
                                    <label class="mb-3">Nome do arquivo</label>
                                    <input
                                        name="nome"
                                        formControlName="nome"
                                        class="nameInput w-full xl:w-11 mt-3"
                                        type="text"
                                        pInputText
                                    />
                                </div>
                                <div class="flex-column flex mt-3 xl:mt-0 w-4">
                                    <label class="mr-2 mb-3"
                                        >Visibilidade do arquivo</label
                                    >
                                    <div
                                        class="form-group"
                                        style="height: 35px"
                                    >
                                        <p-inputSwitch
                                            formControlName="visibilidade"
                                        ></p-inputSwitch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3">
                        <div class="mb-3">
                            <label>Descrição do arquivo</label>
                        </div>
                        <textarea
                            style="resize: none"
                            name="descricao"
                            class="w-full"
                            formControlName="descricao"
                            [rows]="5"
                            [cols]="50"
                            pInputTextarea
                        ></textarea>
                    </div>

                    <div class="mt-3">
                        <div class="mb-3"><label>Arquivo</label></div>
                        <div class="currentFile">
                            <span class="currentFileText">{{
                                currentFileName
                            }}</span>
                        </div>
                    </div>
                    <div class="form-group flex flex-column mt-3">
                        <label class="mb-3">Tags</label>
                        <div class="flex flex-row p-fluid cadastro">
                            <p-chips
                                formControlName="tags"
                                separator=","
                                allowDuplicate
                                style="width: 100%"
                            ></p-chips>
                        </div>
                    </div>
                </div>
                <div class="form-group flex mt-5 justify-content-end w-full">
                    <button
                        (click)="goBackToEditarCategoria()"
                        pButton
                        type="button"
                        label="Cancelar"
                        class="p-button-lg p-button-danger mr-3"
                    ></button>
                    <button
                        pButton
                        type="button"
                        type="submit"
                        label="Salvar"
                        class="p-button-lg"
                    ></button>
                </div>
            </form>
        </div>
    </div>
    <div
        class="w-10 p-5 mt-4"
        style="
            background: #fff;
            border-radius: 15px 15px 15px 15px;
            min-width: 400px;
        "
        *ngIf="historyArchive.length"
    >
        <h2>Histórico de Arquivos</h2>
        <p-table
            [value]="historyArchive"
            [lazy]="true"
            responsiveLayout="scroll"
            [paginator]="true"
            [rows]="10"
            [totalRecords]="totalRecords"
            [loading]="loading"
            (onPage)="changePage($event)"
            (onSort)="sortTable($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">
                        ID do Arquivo<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th>Versão</th>
                    <th pSortableColumn="nome">
                        Nome do Arquivo<p-sortIcon field="nome"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tags">
                        TAG<p-sortIcon field="tags"></p-sortIcon>
                    </th>
                    <th pSortableColumn="usuarioOperacao">
                        Usuário<p-sortIcon field="usuarioOperacao"></p-sortIcon>
                    </th>
                    <th pSortableColumn="dataOperacao">
                        Data e hora de criação/atualização<p-sortIcon
                            field="dataOperacao"
                        ></p-sortIcon>
                    </th>
                    <th pSortableColumn="descricao">
                        Descrição<p-sortIcon field="descricao"></p-sortIcon>
                    </th>
                    <th>Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history>
                <tr>
                    <td>{{ history.arquivo?.codigoArquivo }}</td>
                    <td>{{ history.auditoria?.versao }}</td>
                    <td>{{ history.arquivo?.nome }}</td>
                    <td>{{ history.tags }}</td>
                    <td>{{ history.auditoria?.usuario }}</td>
                    <td>{{ history.dataAtualizacao }}</td>
                    <td>{{ history.arquivo?.descricao }}</td>
                    <td>{{ history.arquivo?.status }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
