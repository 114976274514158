<div *ngIf="authenticated && !isLoading" class="flex align-items-start">
    <div *ngIf="masterDN" style="position: absolute; top: 9rem">
        <div class="menu" (click)="goCategories()">
            <i class="pi pi-inbox"></i>
            <span>Categorias</span>
        </div>
    </div>
    <div class="w-full">
        <router-outlet></router-outlet>
    </div>
</div>
<div *ngIf="!authenticated && !isLoading">Autenticação não funcionou</div>
<div *ngIf="isLoading">Loading</div>
