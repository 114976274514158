import { Component, OnInit } from "@angular/core";
import {
    ConfirmationService,
    PrimeNGConfig,
    MessageService,
} from "primeng/api";
import {
    CategoriasService,
    ICategories,
    IResponse,
} from "./categorias.service";
import { Router } from "@angular/router";

interface IPages {
    first: number;
}

@Component({
    selector: "app-categorias",
    templateUrl: "./categorias.component.html",
    styleUrls: ["./categorias.component.css"],
})
export class CategoriasComponent implements OnInit {
    items = [{ label: "Lista de Categorias" }];
    home = { icon: "pi pi-home", routerLink: "/documentos-ajuda" };
    dataCategories: ICategories[];

    loading: boolean;

    totalRecords: number;

    currentPage: number;

    rows: number = 10;

    sort: string;

    field: string;

    storedCategory: string;

    constructor(
        private CategoriasService: CategoriasService,
        private primengConfig: PrimeNGConfig,
        private router: Router,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) {}

    getData(response: IResponse) {
        this.dataCategories = response.content.map((category) => {
            return {
                nome: category.nome,
                visibilidade: category.visibilidade ? "ativo" : "inativo",
                codigoCategoria: category.codigoCategoria,
            };
        });
        this.totalRecords = response.totalElements;

        if (this.dataCategories) {
            this.loading = false;
            this.primengConfig.ripple = true;
        }
    }

    ngOnInit() {
        this.loading = true;
        this.currentPage = 0;
        this.CategoriasService.getCategories(this.currentPage).subscribe(
            (response) => {
                this.getData(response);
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar categorias",
                });
            }
        );
    }

    changePage(e: IPages) {
        this.loading = true;
        this.currentPage = e.first / this.rows;
        this.CategoriasService.getCategories(
            this.currentPage,
            this.storedCategory
        ).subscribe(
            (response) => {
                this.getData(response);
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar categorias",
                });
            }
        );
    }

    goToRegisterCategory() {
        this.router.navigate(["documentos-ajuda/registrar-categorias"]);
    }

    goToEditCategory(idCategory: number) {
        this.router.navigate([
            `documentos-ajuda/editar-categoria/${idCategory}`,
        ]);
    }

    goToRegisterArchive(idCategory: number) {
        this.router.navigate([
            `documentos-ajuda/cadastro-arquivos/${idCategory}`,
        ]);
    }

    searchCategory() {
        this.currentPage = 0;
        this.CategoriasService.getCategories(
            this.currentPage,
            this.storedCategory
        ).subscribe(
            (response) => {
                response.content.length
                    ? this.getData(response)
                    : this.messageService.add({
                          severity: "error",
                          summary: "Sem Categorias",
                          detail: "Nenhuma categoria encontrada",
                      });
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar categoria",
                });
            }
        );
    }

    storeCategory(category: string) {
        this.storedCategory = category;
        this.searchCategory();
    }

    deleteCategory(id: number) {
        this.CategoriasService.deleteCategory(id).subscribe((response) => {
            this.messageService.add({
                severity: "success",
                summary: "Removida",
                detail: "Categoria removida",
            });

            this.CategoriasService.getCategories(this.currentPage).subscribe(
                (response) => {
                    this.loading = true;
                    this.CategoriasService.getCategories(
                        this.currentPage
                    ).subscribe(
                        (response) => {
                            this.getData(response);
                        },
                        (error) => {
                            this.messageService.add({
                                severity: "error",
                                summary: "Erro",
                                detail: "Erro ao buscar categorias",
                            });
                        }
                    );
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: "Erro ao buscar categorias",
                    });
                }
            );
        }),
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao excluir categoria",
                });
            };
    }

    openModalDelete(category: number) {
        this.confirmationService.confirm({
            message: "Tem certeza que deseja excluir essa categoria?",
            acceptLabel: "Sim",
            rejectLabel: "Não",
            accept: () => {
                this.CategoriasService.getOneCategory(category).subscribe(
                    (response) => {
                        response.arquivos.length
                            ? this.messageService.add({
                                  severity: "error",
                                  summary: "Erro",
                                  detail: "Você não pode excluir uma categoria que possui arquivos",
                              })
                            : this.deleteCategory(category);
                    },
                    (error) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro",
                            detail: "Erro ao buscar dados da categoria",
                        });
                    }
                );
            },
        });
    }
}
