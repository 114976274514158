import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@service/core/auth.service";
import { environment } from "../../../../environments/environment";

export interface ICategories {
    nome: string;
    visibilidade: string;
    codigoCategoria: number;
}
export interface IResponse {
    content: ICategories[];
    totalElements: number;
    arquivos: any[];
    visibilidade: boolean | string;
}

@Injectable({
    providedIn: "root",
})
export class CadastroArquivoService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    createFile(file: FormData) {
        return this.http.post(`${environment.baseUrl}/arquivos`, file, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }
}
