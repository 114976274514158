import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentosAjudaComponent } from "./documentos-ajuda.component";
import { RouterModule } from "@angular/router";
import { TreeModule } from "primeng/tree";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { routes } from "./documentos-ajuda.routing";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { EditarArquivoComponent } from "app/documentos-ajuda/pages/editar-arquivo/editar-arquivo.component";
import { FieldsetModule } from "primeng/fieldset";
import { InputTextModule } from "primeng/inputtext";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TagModule } from "primeng/tag";
import { ChipsModule } from "primeng/chips";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CategoriasComponent } from "./pages/categorias/categorias.component";
import { RegisterCategoriasComponent } from "./pages/registrar-categorias/registrar-categorias.component";
import { PaginatorModule } from "primeng/paginator";
import { MegaMenuModule } from "primeng/megamenu";
import { CadastroArquivoComponent } from "./pages/cadastro-arquivo/cadastro-arquivo.component";
import { EditarCategoriaComponent } from "./pages/editar-categoria/editar-categoria.component";

import { TableModule } from "primeng/table";
import { PesquisaComponent } from "./pages/pesquisa/pesquisa.component";
import { AccordionModule } from "primeng/accordion";
import { ResultadoPesquisaComponent } from "./pages/resultado-pesquisa/resultado-pesquisa.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CalendarModule } from "primeng/calendar";
@NgModule({
    imports: [
        CommonModule,
        TreeModule,
        ButtonModule,
        CardModule,
        ToastModule,
        FormsModule,
        ConfirmDialogModule,
        FieldsetModule,
        InputTextareaModule,
        ReactiveFormsModule,
        TagModule,
        InputSwitchModule,
        InputTextModule,
        PaginatorModule,
        MegaMenuModule,
        ChipsModule,
        AccordionModule,
        BreadcrumbModule,
        TableModule,
        CalendarModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        DocumentosAjudaComponent,
        CategoriasComponent,
        EditarCategoriaComponent,
        RegisterCategoriasComponent,
        CadastroArquivoComponent,
        EditarArquivoComponent,
        PesquisaComponent,
        ResultadoPesquisaComponent,
        BreadcrumbComponent,
    ],
    providers: [{ provide: LOCALE_ID, useValue: "pt-BR" }, ConfirmationService],
    exports: [RouterModule],
})
export class DocumentosAjudasModule {}
