import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@service/core/auth.service";
import { environment } from "environments/environment";

import { take } from "rxjs/operators";

export interface Categoria {
    codigoCategoria: number;
    nome: string;
    visibilidade: boolean;
}

export interface Tag {
    codigoTag: number;
    nomeTag: string;
}

export interface IArquivo {
    codigoArquivo: number;
    nome: string;
    descricao: string;
    arquivoUrl: string;
    categoria: Categoria;
    tags: Tag[];
    visibilidade: boolean;
    qtdDownloads: number;
}

@Injectable({
    providedIn: "root",
})
export class EditarArquivoService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    getFileById(id: number) {
        return this.http.get<IArquivo>(
            `${environment.baseUrl}/arquivos/${id}`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }

    updateFile(id: number, data: FormData) {
        return this.http
            .put<FormData>(`${environment.baseUrl}/arquivos/${id}`, data, {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            })
            .pipe(take(1));
    }

    getFileHistory(
        id: string,
        page: number,
        sort?: string,
        direction?: string
    ) {
        return this.http.get<IArquivo>(
            `${
                environment.baseUrl
            }/arquivos/auditoria/${id}?page=${page}&size=10${
                !!sort ? `&sort=${sort}` : ""
            }${!!direction ? `&direction=${direction}` : ""}`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }
}
