<app-breadcrumb [items]="items" [home]="home"></app-breadcrumb>
<div class="p-5 lg:p-8 w-full flex justify-content-center">
    <div class="w-10">
        <div
            class="flex justify-content-between flex-column md:flex-row w-12"
            style="min-width: 250px"
        >
            <div class="w-12 flex justify-content-start flex-wrap">
                <span class="p-input-icon-left w-5 h-full md: w-8">
                    <i class="pi pi-search"></i>
                    <input
                        type="text"
                        pInputText
                        placeholder="Pesquisar"
                        class="w-12 h-full"
                        (change)="storeCategory($event.target.value)"
                        style="min-width: 150px"
                    />
                </span>
                <button
                    pButton
                    type="button"
                    (click)="searchCategory()"
                    label="Buscar"
                    class="ml-2"
                    style="min-width: 75px"
                ></button>
            </div>
            <button
                pButton
                type="button"
                (click)="goToRegisterCategory()"
                label="Cadastrar categoria"
                style="min-width: 150px"
                class="mt-3 md:mt-0"
            ></button>
        </div>
        <div *ngIf="dataCategories">
            <div
                *ngIf="dataCategories.length"
                class="mt-5 p-3 w-12"
                style="
                    background-color: white;
                    border-radius: 15px 15px 15px 15px;
                "
            >
                <div *ngFor="let categoria of dataCategories" class="w-12">
                    <div
                        class="flex justify-content-between mt-3 align-items-center flex-column md:flex-row"
                    >
                        <div
                            class="flex w-full justify-content-between border-solid border-1 align-items-center p-2"
                            style="
                                border-color: rgb(211, 211, 211) !important;
                                border-radius: 5px;
                                min-width: 250px;
                            "
                        >
                            <div>{{ categoria.nome }}</div>
                            <div class="flex mr-5 align-items-center">
                                <div class="flex align-items-center">
                                    <div
                                        class="mr-2"
                                        style="
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 50%;
                                        "
                                        [ngClass]="[
                                            categoria.visibilidade === 'ativo'
                                                ? 'green'
                                                : 'red'
                                        ]"
                                    ></div>
                                    <span class="mr-3">
                                        {{ categoria.visibilidade }}
                                    </span>
                                </div>
                                <div
                                    class="flex align-items-center justify-content-between"
                                >
                                    <i
                                        class="pi pi-upload"
                                        style="
                                            font-size: 1.2rem;
                                            cursor: pointer;
                                        "
                                        (click)="
                                            goToRegisterArchive(
                                                categoria.codigoCategoria
                                            )
                                        "
                                    ></i>
                                    <i
                                        class="pi pi-pencil ml-3"
                                        style="
                                            font-size: 1.2rem;
                                            cursor: pointer;
                                        "
                                        (click)="
                                            goToEditCategory(
                                                categoria.codigoCategoria
                                            )
                                        "
                                    ></i>
                                    <i
                                        class="pi pi-trash ml-3"
                                        style="
                                            font-size: 1.2rem;
                                            cursor: pointer;
                                        "
                                        (click)="
                                            openModalDelete(
                                                categoria.codigoCategoria
                                            )
                                        "
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p-paginator
                    [rows]="10"
                    [totalRecords]="totalRecords"
                    (onPageChange)="changePage($event)"
                ></p-paginator>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>
<p-confirmDialog
    header="Excluir categoria"
    icon="pi pi-exclamation-triangle"
></p-confirmDialog>
