import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@service/core/auth.service";
import { environment } from "../../../../environments/environment";

export interface IFiles {
    nome: string;
    codigoArquivo: number;
}
export interface ICategories {
    nome: string;
    visibilidade: boolean;
    codigoCategoria: number;
    arquivos: IFiles[];
}
@Injectable({
    providedIn: "root",
})
export class EditarCategoriaService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    updateCategory(category: FormData, codigoCategoria: number) {
        const url = `${environment.baseUrl}/categorias/${codigoCategoria}`;
        return this.http.put<FormData>(url, category, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }

    getCategoryById(codigoCategoria: number) {
        const url = `${environment.baseUrl}/categorias/${codigoCategoria}`;
        return this.http.get<ICategories>(url, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }

    deleteFiles(codigoArquivo: number) {
        const url = `${environment.baseUrl}/arquivos/${codigoArquivo}`;
        return this.http.delete(url, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }

    getAllFiles() {
        const url = `${environment.baseUrl}/arquivos`;
        return this.http.get(url, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }
}
