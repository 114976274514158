import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@service/core/auth.service";
import { take } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

interface ICategory {
    nome: string;
    visibilidade: boolean;
}

@Injectable({
    providedIn: "root",
})
export class RegistrarCategoriasService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    createCategory(category: ICategory) {
        return this.http
            .post(`${environment.baseUrl}/categorias`, category, {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            })
            .pipe(take(1));
    }
}
