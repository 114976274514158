<p-toast></p-toast>
<app-breadcrumb [items]="items" [home]="home"></app-breadcrumb>
<div class="p-5 lg:p-8 w-full flex justify-content-center">
    <div
        class="w-6 p-5"
        style="
            background: #fff;
            border-radius: 15px 15px 15px 15px;
            min-width: 400px;
        "
    >
        <form
            [formGroup]="formNewFile"
            (ngSubmit)="handleRegisterFile()"
            (keydown.enter)="$event.preventDefault()"
        >
            <h2>Cadastrar arquivo</h2>
            <div class="form-group flex align-items-center">
                <div class="flex justify-content-between w-12">
                    <div class="flex flex-column w-12 mr-4">
                        <h6>Nome do arquivo</h6>
                        <input
                            type="text"
                            pInputText
                            name="nome"
                            id="inputName"
                            placeholder="Nome"
                            formControlName="nome"
                            class="nameInput w-full mr-4"
                        />
                    </div>
                    <div
                        class="
                            flex flex-column
                            align-items-center
                            justify-content-between
                        "
                    >
                        <label class="mr-2">Visibilidade </label>
                        <div
                            class="
                                form-group
                                flex
                                justify-content-center
                                align-items-center
                            "
                        >
                            <p-inputSwitch
                                (onChange)="toogleActiveSwitch($event)"
                                name="visibilidade"
                                formControlName="visibilidade"
                                class="inputswitch"
                            >
                            </p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-3">
                <h6>Descrição do arquivo</h6>
                <textarea
                    style="resize: none"
                    name="descricao"
                    class="w-full"
                    formControlName="descricao"
                    rows="5"
                    cols="30"
                    pInputTextarea
                    autoResize="autoResize"
                ></textarea>
            </div>

            <div class="mt-3">
                <h6>Arquivo</h6>
                <div class="flex flex-row align-items-center">
                    <div class="w-full">
                        <label class="labelFileUpload" for="fileUploadLabel">
                            <div>
                                <i
                                    class="pi pi-upload"
                                    style="margin-right: 10px"
                                ></i>
                                Selecionar arquivo
                            </div>
                            <div>
                                <p style="margin-left: 8px">
                                    {{ fileNameInfo }}
                                </p>
                            </div>
                        </label>
                        <input
                            id="fileUploadLabel"
                            class="fileUploadInput w-full xl:w-8"
                            type="file"
                            name="multipartFile"
                            formControlName="multipartFile"
                            accept=".doc, .docx, .docm, .dot, .dotm, .dotx, .pdf, .avi, .txt, .xlsx, .xml, .jpg, .xls, .xlsb, .xlsm, .mpa, .csv, .3gp, .mpeg, .jpeg, .zip, .gif , .mp4"
                            (change)="uploaderFile($event)"
                        />
                    </div>
                </div>
            </div>
            <div *ngIf="!!fileNameInfo">
                <div
                    class="
                        flex
                        w-12
                        justify-content-between
                        mt-3
                        align-items-center
                    "
                >
                    <div
                        class="
                            flex
                            justify-content-between
                            border-solid border-1
                            align-items-center
                            p-2
                            w-full
                        "
                        style="
                            border-color: blue !important;
                            border-radius: 5px;
                        "
                    >
                        <div class="flex align-items-center">
                            <div
                                style="
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                "
                            ></div>
                            <span class="mr-3">{{ fileNameInfo }}</span>
                        </div>
                        <div>
                            <i
                                class="pi pi-trash"
                                style="font-size: 1.2rem; cursor: pointer"
                                (click)="openModalDelete()"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group flex flex-column mt-3 w-full">
                <h6>Tags</h6>
                <div class="flex flex-row p-fluid cadastro">
                    <p-chips
                        allowDuplicate
                        formControlName="tags"
                        style="width: 100%"
                    ></p-chips>
                </div>
            </div>

            <div class="form-group mt-5 flex justify-content-end">
                <button
                    (click)="handleCancelRegisterFile()"
                    pButton
                    type="button"
                    label="Cancelar"
                    class="p-button-lg p-button-danger mr-3"
                ></button>
                <button
                    [disabled]="formNewFile.invalid"
                    pButton
                    type="submit"
                    label="Cadastrar arquivo"
                    class="p-button-lg"
                ></button>
            </div>
        </form>
    </div>
    <p-confirmDialog
        header="Excluir arquivo"
        icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>
</div>
