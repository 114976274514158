import { Component, OnInit } from "@angular/core";
import { PesquisaService } from "./pesquisa.service";
import { MessageService } from "primeng/api";
import { ICategories } from "../editar-categoria/editar-categoria.service";
import { Router } from "@angular/router";
import { Target } from "@angular/compiler";
import { PermissaoPerfil } from "@shared/enum/permissao-perfil.enum";

interface ITags {
    codigoTag: number;
    nomeTag: string;
}

interface IArchive {
    codigoArquivo: number;
    nome: string;
    descricao: string;
    arquivoUrl: string;
    categoria: {
        codigoCategoria: number;
        nome: string;
        visibilidade: boolean;
    };
    tags: ITags[];
    visibilidade: boolean;
    qtdDownloads: number;
}

interface IResponseArchives {
    content: IArchive[];
}

interface IResponseCategories {
    content: ICategories[];
    totalElements: number;
}
@Component({
    selector: "app-pesquisa",
    templateUrl: "./pesquisa.component.html",
    styleUrls: ["./pesquisa.component.css"],
})
export class PesquisaComponent implements OnInit {
    constructor(
        private pesquisaService: PesquisaService,
        private messageService: MessageService,
        private router: Router
    ) {}

    emphasisArchives: IArchive[] = [];

    lastArchives: IArchive[] = [];

    categories: any[] = [];

    totalRecords: number;

    currentPage: number = 0;

    rows: number = 6;

    loading: boolean;

    search: string;

    masterDN: boolean;

    allFiles: boolean = false;

    toArray(items: object) {
        return Object.keys(items).map((key) => items[key]);
    }

    downloadFile(fileId: number, fileName: string) {
        this.pesquisaService.getFile(fileId).subscribe(
            (response: any) => {
                let fileExtension = response.headers
                    .get("Content-Disposition")
                    .split(".")
                    .pop()
                    .replaceAll('"', "");

                const blob = new Blob([response.body], {
                    type: response.body.type,
                });
                let a = document.createElement("a");
                a.download = `${fileName}.${fileExtension}`;
                a.href = window.URL.createObjectURL(blob);
                a.click();
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao baixar arquivo",
                });
            }
        );
    }

    goSearch() {
        this.router.navigate([
            `documentos-ajuda/resultado-pesquisa/${this.search}`,
        ]);
    }

    goCategories() {
        this.router.navigate([`documentos-ajuda/categorias`]);
    }

    storeSearch(event: { target: { value: string } }) {
        this.search = event.target.value;
    }

    changePage(e: { first: number }) {
        this.loading = true;
        this.currentPage = e.first / this.rows;
        this.pesquisaService.getCategories(this.currentPage).subscribe(
            (resp: IResponseCategories) => {
                if (this.currentPage !== 0) {
                    const formatedData = resp.content.map((data) => ({
                        ...data,
                        arquivos: data.arquivos.slice(0, 5),
                        todosArquivos: data.arquivos,
                    }));

                    this.categories = formatedData;
                } else {
                    const formatedData = resp.content
                        .splice(0, 4)
                        .map((data) => ({
                            ...data,
                            arquivos: data.arquivos.slice(0, 5),
                            todosArquivos: data.arquivos,
                        }));

                    this.categories = formatedData;
                }
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar categorias",
                });
            }
        );
    }

    ngOnInit(): void {
        const user = JSON.parse(localStorage?.getItem("usuario"))
        this.masterDN = user?.tipoPerfil === "DN" && user?.perfil.replace(' ', '').replace(' ', '') ===
            PermissaoPerfil['MASTER_DN'].replace(' ', '').replace(' ', '');
        this.pesquisaService.emphasisArchives().subscribe(
            (resp: IResponseArchives) => {
                this.emphasisArchives = resp.content;
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar arquivos em destaque",
                });
            }
        );

        this.pesquisaService.lastArchives().subscribe(
            (resp: IResponseArchives) => {
                this.lastArchives = resp.content;
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar os ultimos arquivos",
                });
            }
        );

        this.pesquisaService.getCategories(0).subscribe(
            (resp: IResponseCategories) => {
                const formatedData = resp.content.splice(0, 4).map((data) => ({
                    ...data,
                    arquivos: data.arquivos.slice(0, 5),
                    todosArquivos: data.arquivos,
                }));

                this.categories = formatedData;
                this.totalRecords = resp.totalElements + 2;
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar categorias",
                });
            }
        );
    }

    getAllFiles(codigoCategoria: number) {
        this.allFiles = !this.allFiles;
        const index = this.categories.findIndex(
            (element) => element.codigoCategoria === codigoCategoria
        );
        if (this.allFiles) {
            this.categories[index].arquivos =
                this.categories[index].todosArquivos;
        } else {
            this.categories[index].arquivos = this.categories[
                index
            ].todosArquivos.slice(0, 5);
        }
    }
}
