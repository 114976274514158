import {
    APP_BASE_HREF,
    registerLocaleData,
    CommonModule,
} from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { ToastModule } from "primeng/toast";
import { MessagesModule } from "primeng/messages";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { NgHttpLoaderModule } from "ng-http-loader";
import { TreeModule } from "primeng/tree";
import { ButtonModule } from "primeng/button";
import { ListboxModule } from "primeng/listbox";
import { FileUploadModule } from "primeng/fileupload";
import { TagModule } from "primeng/tag";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ChipsModule } from "primeng/chips";
import { BrowserModule } from "@angular/platform-browser";

registerLocaleData(localePt, "pt-BR");
import { InputTextModule } from "primeng/inputtext";
import { ConfirmationService, MenuItem } from "primeng/api";
import { InputSwitchModule } from "primeng/inputswitch";
import { TableModule } from "primeng/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaginatorModule } from "primeng/paginator";
import { DocumentosAjudasModule } from "./documentos-ajuda/documentos-ajudas.module";
import { TokenInterceptor } from "@service/core/interceptor.service";
registerLocaleData(localePt, "pt-BR");
@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes),
        BrowserModule,
        BrowserAnimationsModule,
        InputSwitchModule,
        HttpClientModule,
        NgHttpLoaderModule,
        ToastModule,
        ConfirmDialogModule,
        MessagesModule,
        TreeModule,
        ButtonModule,
        FormsModule,
        InputTextModule,
        InputSwitchModule,
        ReactiveFormsModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        ListboxModule,
        FileUploadModule,
        TagModule,
        TableModule,
        ReactiveFormsModule,
        InputTextModule,
        InputTextareaModule,
        ChipsModule,
        DocumentosAjudasModule,
        CommonModule,
        PaginatorModule,
    ],
    declarations: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { provide: APP_BASE_HREF, useValue: "/" },
        ConfirmationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
