import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, shareReplay, tap } from 'rxjs/operators';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ServicosService {
  public urls: any = {};
  public modulos: any = {};
  private urls$;
  private modulos$;
  private headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  teste: any;
  constructor(public router: Router, public http: HttpClient) {}

  buscarUrls() {
    if (!this.urls$) {
      return this.buscarUrlsCached();
      
      // .subscribe(url => {
      //   console.log('Aqui Caraiiiiiiiiiiiiiiiiiiiiiiii', url); 
      //   return url;
      // },  err => {
      //   console.log(err);        
      // })
      // this.buscarUrlsCached().then((urls) => {this.urls$ = urls});   
      // console.log('this.servicos.urls. this.urls$', this.urls$ )
     
    }
    return this.urls;
  }

  buscarUrlsCached() {
    const url = `${environment.baseUrl}sgf-administracao/servicos/corporativo`;
    return this.http.get<any>(url, this.headers);

  //   return new Promise((resolve: any) => {
  //     setTimeout(() => {
  //       .subscribe(
  //         res=> { resolve( `${res}`)  }, err =>{
  //           console.log('resposta de err', err );
  //         }            
  //       )            
  //     }, 800);
  // })
  }

  buscarModulos() {
    if (!this.modulos$) {
      this.modulos$ = this.buscarModulosCached();
    }
    return this.modulos$;
  }

  buscarModulosCached() {
    return this.http
      .get<any>(environment.baseUrl + 'sgf-administracao/servicos/modulos', this.headers)
      .pipe(
        map(modulos => this.reduceVetorParaObjeto(modulos)),
        shareReplay(1), // Cache
        tap(res => (this.modulos = res))
      );
  }

  private reduceVetorParaObjeto(modulos: any): any {
    return modulos.reduce(
      // tslint:disable-next-line:no-shadowed-variable
      (map, obj) => ((map[obj.nome] = obj.url), map),
      {}
    );
  }
}
