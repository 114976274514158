import { Component, OnInit, Input } from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
    selector: "app-breadcrumb",
    templateUrl: "./breadcrumb.component.html",
    styleUrls: ["./breadcrumb.component.css"],
})
export class BreadcrumbComponent implements OnInit {
    @Input() items: MenuItem[];
    @Input() home: MenuItem;

    constructor() {}

    ngOnInit() {}
}
