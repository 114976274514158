import { Routes } from "@angular/router";
import { DocumentosAjudaComponent } from "./documentos-ajuda/documentos-ajuda.component";

export const AppRoutes: Routes = [
    {
        path: "documentos-ajuda",
        component: DocumentosAjudaComponent,
        children: [
            {
                path: "",
                loadChildren: () =>
                    import("./documentos-ajuda/documentos-ajudas.module").then(
                        (m) => m.DocumentosAjudasModule
                    ),
            },
        ],
    },
];
