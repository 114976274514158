import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ResultadoPesquisaService } from "../resultado-pesquisa/resultado-pesquisa.service";
import { EditarArquivoService } from "./editar-arquivo.service";

@Component({
    selector: "app-editar-arquivo",
    templateUrl: "./editar-arquivo.component.html",
    styleUrls: ["./editar-arquivo.component.css"],
    providers: [MessageService],
})
export class EditarArquivoComponent implements OnInit {
    formRegister: FormGroup;
    uploadFile: File | string;
    fileId: string;
    categoryId: string;
    showCurrentFile: boolean;
    currentFileName: string;

    @ViewChild("uploadInput") input;

    items = [];
    home = { icon: "pi pi-home", routerLink: "/documentos-ajuda" };

    constructor(
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private service: EditarArquivoService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    loading: boolean = false;
    historyArchive: any = [];
    totalRecords: number;
    currentPage: number = 0;
    search: string;
    direction: string = "ASC";
    sort: string = "nome";
    rows: number = 1;

    ngOnInit(): void {
        this.categoryId = this.route.snapshot.paramMap.get("id-categoria");
        this.fileId = this.route.snapshot.paramMap.get("id-arquivo");

        this.items = [
            {
                label: "Lista de Categorias",
                routerLink: "/documentos-ajuda/categorias",
            },
            {
                label: "Editar Categoria",
                routerLink: `/documentos-ajuda/editar-categoria/${this.categoryId}`,
            },
            { label: "Editar Arquivo" },
        ];

        this.service.getFileById(Number(this.fileId)).subscribe(
            (response) => {
                this.formRegister
                    .get("visibilidade")
                    .setValue(response.visibilidade);
                this.formRegister.get("nome").setValue(response.nome);
                this.formRegister.get("descricao").setValue(response.descricao);
                this.formRegister
                    .get("tags")
                    .setValue(response.tags.map((item) => item.nomeTag));
                this.showCurrentFile = response.arquivoUrl ? true : false;
                this.currentFileName = response.arquivoUrl.split("\\").pop();
            },
            (error) => {
                this.showNotice(
                    "error",
                    "Erro",
                    "Não foi possível buscar as informações do arquivo"
                );
            }
        );

        this.formRegister = this.formBuilder.group({
            nome: ["", [Validators.required]],
            descricao: [""],
            tags: [[], [Validators.required]],
            visibilidade: [false],
        });

        this.loading = true;
        this.currentPage = 0;
        this.service.getFileHistory(this.fileId, this.currentPage).subscribe(
            (response) => {
                this.getData(response);
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar resultado",
                });
            }
        );
    }

    getData(response: any) {
        this.historyArchive = response.content.map((item: any) => {
            return {
                ...item,
                dataAtualizacao: new Date(
                    item.arquivo?.dataAtualizacao ?? item.arquivo?.dataCriacao
                ).toLocaleString("pt-BR"),
                tags: item.arquivo?.tags.join(", "),
            };
        });
        this.totalRecords = response.totalElements;
        if (this.historyArchive) {
            this.loading = false;
        }
    }

    changePage(e: any) {
        console.log("page");
        this.loading = true;
        this.currentPage = e.first / this.rows;
        this.service.getFileHistory(this.fileId, this.currentPage).subscribe(
            (response) => {
                this.getData(response);
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao buscar resultado",
                });
            }
        );
    }

    sortTable(event: { field: string }) {
        this.direction = this.direction === "ASC" ? "DESC" : "ASC";
        this.sort = event.field;
        this.service
            .getFileHistory(
                this.fileId,
                this.currentPage,
                this.sort,
                this.direction
            )
            .subscribe(
                (response) => {
                    this.getData(response);
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: "Erro ao buscar resultados",
                    });
                }
            );
    }

    showNotice(severity: string, summary: string, detail: string) {
        this.messageService.add({
            severity: severity,
            summary: summary,
            detail: detail,
        });
    }

    handleUpload(e: { target: HTMLInputElement & EventTarget }) {
        const maxSize = 104857600;
        const fileGet = e.target.files[0];

        if (fileGet.size > maxSize) {
            this.messageService.add({
                severity: "error",
                summary: "Erro!",
                detail: "O arquivo ultrapassou o tamanho limite!",
            });
        } else {
            this.uploadFile = e.target.files[0];
        }

        e.target.value = "";
    }

    goBackToEditarCategoria() {
        this.router.navigate([
            `documentos-ajuda/editar-categoria/${this.categoryId}`,
        ]);
    }

    updateFile() {
        if (this.formRegister.status === "INVALID") {
            this.showNotice(
                "error",
                "Erro",
                "Nome e Tags não podem estar vázios."
            );
        } else if (this.formRegister.value.descricao.length > 250) {
            this.showNotice(
                "error",
                "Erro",
                "Descrição não pode passar de 250 caracteres"
            );
        } else {
            const formData = new FormData();
            const formRegisterData = this.formRegister.getRawValue();
            for (const key in formRegisterData) {
                formData.append(key, formRegisterData[key]);
            }
            formData.append("multipartFile", this.uploadFile);
            formData.append("codigoCategoria", this.categoryId);
            this.service.updateFile(Number(this.fileId), formData).subscribe(
                (response) => {
                    this.showNotice(
                        "success",
                        "Sucesso",
                        "Arquivo atualizado com sucesso"
                    );
                    setTimeout(() => {
                        this.goBackToEditarCategoria();
                    }, 1500);
                },
                (error) => {
                    this.showNotice(
                        "error",
                        "Erro",
                        "Não foi possível atualizar o arquivo"
                    );
                }
            );
        }
    }
}
