import { Component, OnInit } from "@angular/core";
import { ConfirmationService, PrimeNGConfig } from "primeng/api";
import { MessageService } from "primeng/api";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { CadastroArquivoService } from "./cadastro-arquivo.service";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "@service/core/auth.service";

@Component({
    selector: "app-cadastro-arquivo",
    templateUrl: "./cadastro-arquivo.component.html",
    styleUrls: ["./cadastro-arquivo.component.css"],
})
export class CadastroArquivoComponent implements OnInit {
    items = [
        {
            label: "Lista de Categorias",
            routerLink: "/documentos-ajuda/categorias",
        },
        { label: " Cadastrar arquivo" },
    ];
    home = { icon: "pi pi-home", routerLink: "/documentos-ajuda" };
    formNewFile: FormGroup;
    sendFileIsDisabled: boolean = true;
    fileNameInfo: string;
    visibilityFileOnPage: string;
    categoryId: string;

    constructor(
        private primengConfig: PrimeNGConfig,
        private messageService: MessageService,
        private FormBuilder: FormBuilder,
        private service: CadastroArquivoService,
        private route: ActivatedRoute,
        private router: Router,
        public fb: FormBuilder,
        public authService: AuthService,
        private confirmationService: ConfirmationService
    ) {}

    showSuccess() {
        this.messageService.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Arquivo cadastrado com sucesso",
        });
    }

    showError() {
        this.messageService.add({
            severity: "error",
            summary: "Erro!",
            detail: "Ocorreu um problema ao tentar cadastrar seu arquivo, tente novamente mais tarde!",
        });
    }

    toogleActiveSwitch($event: { checked: boolean }) {
        $event.checked === true
            ? (this.visibilityFileOnPage = "Arquivo ativo")
            : (this.visibilityFileOnPage = "Arquivo inativo");
    }

    uploaderFile(event: { target: { files: any[] } }) {
        const maxSize = 104857600;
        const fileGet = event.target.files[0];
        if (fileGet.size > maxSize) {
            this.messageService.add({
                severity: "error",
                summary: "Erro!",
                detail: "Não é possivel realizar o upload de um arquivo com mais de 100mb",
            });
        } else {
            this.fileNameInfo = fileGet.name; // nome do arquivo que aparece no HTML
            this.formNewFile.get("multipartFile").setValue(fileGet);
        }
    }

    handleRegisterFile() {
        this.route.params.subscribe(
            (params) => (this.categoryId = params["idCategory"])
        );
        const formData = new FormData();
        const valoresForm = this.formNewFile.getRawValue();

        Object.keys(valoresForm).forEach((key) =>
            formData.append(key, valoresForm[key])
        );
        formData.append("codigoCategoria", this.categoryId);
        this.service.createFile(formData).subscribe(
            (response) => {
                this.formNewFile.reset();
                this.formNewFile.get("visibilidade").setValue(false);
                this.fileNameInfo = "";
                this.showSuccess();
                setTimeout(() => {
                    this.handleCancelRegisterFile();
                }, 1500);
            },
            (error) => this.showError()
        );
    }

    handleCancelRegisterFile() {
        this.router.navigate(["documentos-ajuda/categorias"]);
    }

    openModalDelete() {
        this.confirmationService.confirm({
            message: "Tem certeza que deseja excluir esse arquivo?",
            acceptLabel: "Sim",
            rejectLabel: "Não",
            accept: () => {
                this.formNewFile.get("multipartFile").reset();
                this.fileNameInfo = "";
            },
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe(
            (params) => (this.categoryId = params["idCategory"])
        );
        this.primengConfig.ripple = true;
        this.visibilityFileOnPage = "Arquivo ativo";
        this.formNewFile = this.FormBuilder.group({
            nome: ["", Validators.required],
            descricao: [""],
            visibilidade: true,
            tags: ["", Validators.required],
            multipartFile: ["", Validators.required],
        });
    }
}
