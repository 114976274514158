<app-breadcrumb [items]="items" [home]="home"></app-breadcrumb>
<div class="p-8 w-full flex justify-content-center">
    <div class="w-10">
        <div
            class="flex w-full mb-3 justify-content-between flex-column md:flex-row"
            style="min-width: 250px"
        >
            <div class="flex w-full justify-content-between">
                <span class="p-input-icon-left w-10 mr-3 h-full">
                    <i class="pi pi-search"></i>
                    <input
                        type="text"
                        pInputText
                        placeholder="Pesquisar"
                        class="w-full h-full"
                        (change)="storeFiles($event.target.value)"
                        style="min-width: 150px"
                    />
                </span>
                <div class="flex mr-3 align-items-center">
                    <label for="min-max" class="mr-1">Período:</label>
                    <p-calendar
                        [(ngModel)]="dateInicial"
                        dateFormat="dd/mm/yy"
                        class="mr-1"
                    ></p-calendar>
                    <span class="ml-1 mr-1">a</span>
                    <p-calendar
                        [(ngModel)]="dateFinal"
                        dateFormat="dd/mm/yy"
                    ></p-calendar>
                </div>
                <button
                    pButton
                    type="button"
                    label="Buscar"
                    (click)="searchFiles()"
                    style="min-width: 75px"
                ></button>
            </div>
        </div>
        <div
            class="mt-5 w-full p-5"
            style="background: white; border-radius: 15px 15px 0px 0px"
        >
            <p-table
                [value]="arquivos"
                [lazy]="true"
                responsiveLayout="scroll"
                (onSort)="sortTable($event)"
                [paginator]="false"
                [loading]="loading"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="categoriaNome">
                            Nome da Categoria<p-sortIcon
                                field="categoriaNome"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="nome">
                            Nome do Arquivo
                            <p-sortIcon field="nome"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataCriacao">
                            Data/Hora da Criação
                            <p-sortIcon field="dataCriacao"></p-sortIcon>
                        </th>
                        <th pSortableColumn="qtdDownloads">
                            Quantidade de Downloads
                            <p-sortIcon field="qtdDownloads"></p-sortIcon>
                        </th>
                        <th>Opções</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-arquivos>
                    <tr>
                        <td>{{ arquivos.categoria.nome }}</td>
                        <td>{{ arquivos.nome }}</td>
                        <td>{{ arquivos.dataCriacao }}</td>
                        <td>
                            {{
                                arquivos.qtdDownloads === null
                                    ? 0
                                    : arquivos.qtdDownloads
                            }}
                        </td>
                        <td>
                            <i
                                class="pi pi-download ml-3"
                                style="font-size: 1.2rem; cursor: pointer"
                                (click)="
                                    downloadFile(
                                        arquivos.codigoArquivo,
                                        arquivos.nome
                                    )
                                "
                            ></i>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="10"
                [totalRecords]="totalRecords"
                (onPageChange)="changePage($event)"
            ></p-paginator>
        </div>
    </div>
</div>
<p-toast></p-toast>
