<app-breadcrumb [items]="items" [home]="home"></app-breadcrumb>
<div class="p-5 lg:p-8 w-full flex justify-content-center">
    <div class="w-10" style="min-width: 400px">
        <form
            [formGroup]="formEditCategory"
            (ngSubmit)="sendData()"
            class="w-full"
        >
            <div
                class="flex justify-content-start align-items-center flex-wrap w-12"
            >
                <div class="flex w-12 lg:w-6" *ngIf="category">
                    <div class="flex justify-content-between w-12">
                        <div class="flex flex-column w-12 mr-4">
                            <h6>Nome</h6>
                            <input
                                name="nome"
                                type="text"
                                pInputText
                                id="inputName"
                                placeholder="Nome"
                                formControlName="nome"
                                class="w-full mr-4"
                            />
                        </div>
                        <div
                            class="flex flex-column align-items-center justify-content-between"
                        >
                            <label class="mr-2">Status</label>
                            <div
                                class="form-group flex justify-content-center align-items-center"
                            >
                                <p-inputSwitch
                                    formControlName="visibilidade"
                                ></p-inputSwitch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="category">
                <div
                    *ngIf="category.arquivos.length"
                    class="mt-5 p-3"
                    style="
                        background-color: white;
                        border-radius: 15px 15px 15px 15px;
                    "
                >
                    <h2>Arquivos</h2>
                    <div *ngFor="let arquivo of category.arquivos">
                        <div
                            class="flex justify-content-between mt-3 align-items-center"
                        >
                            <div
                                class="flex w-12 justify-content-between border-solid border-1 align-items-center p-2"
                                style="
                                    border-color: #ced4da !important;
                                    border-radius: 5px;
                                "
                            >
                                <div style="max-width: 1000px">
                                    {{ arquivo.nome }}
                                </div>
                                <div
                                    class="flex mr-5 align-items-center"
                                    style="min-width: 50px"
                                >
                                    <div>
                                        <i
                                            class="pi pi-pencil"
                                            style="
                                                font-size: 1.2rem;
                                                cursor: pointer;
                                            "
                                            (click)="
                                                goToEditFile(
                                                    arquivo.codigoArquivo
                                                )
                                            "
                                        ></i>
                                        <i
                                            class="pi pi-trash ml-3"
                                            style="
                                                font-size: 1.2rem;
                                                cursor: pointer;
                                            "
                                            (click)="
                                                openModalDelete(
                                                    arquivo.codigoArquivo
                                                )
                                            "
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <p-paginator
                        [rows]="rows"
                        [totalRecords]="totalRecords"
                        (onPageChange)="changePage($event)"
                    ></p-paginator> -->
                </div>
            </div>
            <div class="mt-5 flex justify-content-end">
                <button
                    pButton
                    pRipple
                    label="Cancelar"
                    class="p-button-danger mr-3"
                    (click)="goBackToCategorys()"
                ></button>
                <p-button type="submit" label="Salvar"></p-button>
            </div>
        </form>
    </div>
</div>
<p-confirmDialog
    header="Excluir Arquivos"
    icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<p-toast></p-toast>
