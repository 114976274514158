import { Routes } from "@angular/router";
import { EditarArquivoComponent } from "app/documentos-ajuda/pages/editar-arquivo/editar-arquivo.component";
import { EditarCategoriaComponent } from "app/documentos-ajuda/pages/editar-categoria/editar-categoria.component";
import { AuthGuard } from "@service/core/auth-guard.service";
import { ServicosGuard } from "@service/core/servicos-guard.service";
import { CadastroArquivoComponent } from "./pages/cadastro-arquivo/cadastro-arquivo.component";
import { RegisterCategoriasComponent } from "./pages/registrar-categorias/registrar-categorias.component";
import { CategoriasComponent } from "./pages/categorias/categorias.component";
import { PesquisaComponent } from "./pages/pesquisa/pesquisa.component";
import { ResultadoPesquisaComponent } from "./pages/resultado-pesquisa/resultado-pesquisa.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";

export const routes: Routes = [
    {
        path: "categorias",
        component: CategoriasComponent,
    },
    {
        path: "editar-categoria/:id",
        component: EditarCategoriaComponent,
    },
    {
        path: "registrar-categorias",
        component: RegisterCategoriasComponent,
    },
    {
        path: "cadastro-arquivos/:idCategory",
        component: CadastroArquivoComponent,
    },
    {
        path: "categoria/:id-categoria/editar-arquivo/:id-arquivo",
        component: EditarArquivoComponent,
    },
    {
        path: "resultado-pesquisa/:search",
        component: ResultadoPesquisaComponent,
    },
    {
        path: "",
        component: PesquisaComponent,
    },
];
