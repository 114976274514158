import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@service/core/auth.service";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class PesquisaService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    emphasisArchives() {
        return this.http.get(
            `${environment.baseUrl}/arquivos?page=0&size=5&direction=DESC&sort=arquivo.qtdDownloads`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }
    lastArchives() {
        return this.http.get(
            `${environment.baseUrl}/arquivos?page=0&size=5&direction=DESC&sort=arquivo.dataCriacao`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }

    getCategories(page: number) {
        return this.http.get(
            `${environment.baseUrl}/categorias?page=${page}&size=6&direction=ASC&sort=nome`,
            {
                headers: {
                    authorization: `Bearer ${this.authService.access_token}`,
                },
            }
        );
    }

    getFile(id: number) {
        return this.http.get(`${environment.baseUrl}/arquivos/download/${id}`, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
            observe: "response",
            responseType: "blob",
        });
    }

    getCategoryById(id: number) {
        return this.http.get(`${environment.baseUrl}/categorias/${id}`, {
            headers: {
                authorization: `Bearer ${this.authService.access_token}`,
            },
        });
    }
}
