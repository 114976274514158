import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { RegistrarCategoriasService } from "./registrar-categorias.service";

@Component({
    selector: "app-register-categorias",
    templateUrl: "./registrar-categorias.component.html",
    styleUrls: ["./registrar-categorias.component.css"],
})
export class RegisterCategoriasComponent implements OnInit {
    items = [
        {
            label: "Lista de Categorias",
            routerLink: "/documentos-ajuda/categorias",
        },
        { label: "Registro de Categorias" },
    ];
    home = { icon: "pi pi-home", routerLink: "/documentos-ajuda" };
    formNewCategory: FormGroup;
    inputChecked: boolean = true;

    constructor(
        private FormBuilder: FormBuilder,
        private service: RegistrarCategoriasService,
        private messageService: MessageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.formNewCategory = this.FormBuilder.group({
            nome: ["", [Validators.required]],
            inputChecked: true,
        });
    }

    handleInputValue() {
        this.inputChecked = !this.inputChecked;
    }

    goBack() {
        this.router.navigate([`documentos-ajuda/categorias`]);
    }

    sendCategory(): void {
        const inputName = document.getElementById("inputName");

        if (this.formNewCategory.get("nome")?.errors?.required) {
            inputName.classList.add("ng-dirty");
        } else {
            this.service
                .createCategory({
                    nome: this.formNewCategory.get("nome").value,
                    visibilidade: this.inputChecked,
                })
                .subscribe(
                    (success) => {
                        this.messageService.add({
                            severity: "success",
                            summary: "Criada",
                            detail: "Categoria criada",
                        });
                        this.formNewCategory.reset();
                        setTimeout(() => {
                            this.goBack();
                        }, 1500);
                    },
                    (error) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro",
                            detail: error.error.message,
                        });
                    }
                );
        }
    }
}
