import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouteConfigLoadStart, Router } from "@angular/router";
import { IFiles, IResponse } from "./resultado-pesquisa.service";
import { ResultadoPesquisaService } from "./resultado-pesquisa.service";
import { PrimeNGConfig, MessageService } from "primeng/api";

interface IPages {
    first: number;
}

@Component({
    selector: "app-resultado-pesquisa",
    templateUrl: "./resultado-pesquisa.component.html",
    styleUrls: ["./resultado-pesquisa.component.css"],
})
export class ResultadoPesquisaComponent implements OnInit {
    items = [{ label: "Resultado Pesquisa" }];
    home = { icon: "pi pi-home", routerLink: "/documentos-ajuda" };

    data: Date[];
    arquivos: IFiles[];

    loading: boolean;

    search: string;
    direction: string = "ASC";
    sort: string = "categoria.nome";
    dateFinal: string;
    dateInicial: string;
    dateInicialFormatada: string;
    dateFinalFormatada: string;
    rows: number = 10;

    currentPage: number;
    totalRecords: number;

    constructor(
        private resultadoService: ResultadoPesquisaService,
        private route: ActivatedRoute,
        private primengConfig: PrimeNGConfig,
        private messageService: MessageService
    ) {}

    getData(response: IResponse) {
        this.arquivos = response.content.map((arquivo) => ({
            ...arquivo,
            dataCriacao: new Date(arquivo.dataCriacao).toLocaleString("pt-BR"),
        }));
        this.totalRecords = response.totalElements;
        if (this.arquivos) {
            this.loading = false;
            this.primengConfig.ripple = true;
        }
    }

    ngOnInit(): void {
        this.loading = true;
        this.primengConfig.ripple = true;
        this.currentPage = 0;
        this.search = this.route.snapshot.params.search;
        this.resultadoService
            .getArquivos(
                this.currentPage,
                this.search,
                this.direction,
                this.sort
            )
            .subscribe(
                (response) => {
                    this.getData(response);
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: "Erro ao buscar resultado",
                    });
                }
            );
    }

    changePage(e: IPages) {
        this.loading = true;
        this.currentPage = e.first / this.rows;
        this.resultadoService
            .getArquivos(
                this.currentPage,
                this.search,
                this.direction,
                this.sort
            )
            .subscribe(
                (response) => {
                    this.getData(response);
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: "Erro ao buscar categorias",
                    });
                }
            );
    }

    sortTable(event: { field: string }) {
        this.direction = this.direction === "ASC" ? "DESC" : "ASC";
        switch (event.field) {
            case "categoriaNome":
                this.sort = "categoria.nome";
                break;
            case "nome":
                this.sort = "arquivo.nome";
                break;
            case "dataCriacao":
                this.sort = "arquivo.dataCriacao";
                break;
            case "qtdDownloads":
                this.sort = "arquivo.qtdDownloads";
                break;
        }
        this.resultadoService
            .getArquivos(
                this.currentPage,
                this.search,
                this.direction,
                this.sort,
                this.dateInicialFormatada,
                this.dateFinalFormatada
            )
            .subscribe(
                (response) => {
                    this.getData(response);
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: "Erro ao buscar resultados",
                    });
                }
            );
    }

    formataData() {
        const clearTime = (date) => {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            return date;
        };

        const addOneDay = (date) => {
            const newDay = new Date(date);
            newDay.setDate(newDay.getDate() + 1);
            return newDay;
        };

        if (this.dateInicial && this.dateFinal) {
            this.dateInicialFormatada = clearTime(
                this.dateInicial
            ).toISOString();

            this.dateFinalFormatada = clearTime(
                addOneDay(this.dateFinal)
            ).toISOString();
        } else {
            this.dateFinalFormatada = "";
            this.dateInicialFormatada = "";
        }
    }

    searchFiles() {
        this.formataData();
        this.currentPage = 0;
        this.resultadoService
            .getArquivos(
                this.currentPage,
                this.search,
                this.direction,
                this.sort,
                this.dateInicialFormatada,
                this.dateFinalFormatada
            )
            .subscribe(
                (response) => {
                    response.content.length
                        ? this.getData(response)
                        : this.messageService.add({
                              severity: "error",
                              summary: "Sem resultado",
                              detail: "Nenhuma resultado encontrado",
                          });
                },
                (error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro",
                        detail: "Erro ao pesquisar",
                    });
                }
            );
    }

    storeFiles(files: string) {
        this.search = files;
        this.searchFiles();
    }

    downloadFile(fileId: number, fileName: string) {
        this.resultadoService.getDownloadFile(fileId).subscribe(
            (response: any) => {
                let fileExtension = response.headers
                    .get("Content-Disposition")
                    .split(".")
                    .pop()
                    .replaceAll('"', "");

                const blob = new Blob([response.body], {
                    type: response.body.type,
                });
                let a = document.createElement("a");
                a.download = `${fileName}.${fileExtension}`;
                a.href = window.URL.createObjectURL(blob);
                a.click();
            },
            (error) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao baixar arquivo",
                });
            }
        );
    }
}
